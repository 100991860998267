import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import styled from "@emotion/styled";
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import GatsbyImage from 'gatsby-image'
import styles from '../constants/theme'
import virtualSymposiumImage from '../images/winter-PD-Image.png'

import SEO from '../components/seo'
import Link from '../components/Link'
import Layout from '../components/layout'
import Text from '../components/Text'
import H1 from '../components/H1'
import HeroBG from '../components/HeroBG'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'
import OcmaColorsBar from '../components/OcmaColorsBar'
import StyledLink from '../components/StyledLink'

import Conference from '../images/Conference.png'

import cf2022 from '../images/cf2022.png'

export default function Events() {
  const eventsPageQuery = useStaticQuery(graphql`
    query eventsPageQuery {
      events: wordpressPage(title: { eq: "Events" }) {
        title
        acf {
          hero_image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          upcoming_events_header
          upcoming_event_promo {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1080) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          past_events {
            year
            events {
              title
              subtitle
              link
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 411, toFormat: JPG, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          archive_events_title
          archive_events {
            title
            year
            file {
              acf_fc_layout
              link
              file {
                url {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `)

  const eventsACF = eventsPageQuery.events.acf

  return (
    <Layout>
      <SEO title={eventsPageQuery.events.title} keywords={['Math', 'Events']} />
      <HeroBG imageURL={eventsACF.hero_image.localFile.childImageSharp.fluid} />

      <ThemeProvider theme={styles.palette.blue}>
        <div className="container text-center">
          <H1>Events</H1>
        </div>
      </ThemeProvider>
      <MainContentWrap>
        <CustomContainer>
          <ContentSection
            css={css`
              margin-bottom: ${styles.margin.mid};
              text-align: center;
            `}
          >
            {/* <div className="text-center">
              <h1>OCMA Conference 2022: Come Back to the Past…</h1>
              <br />
              <br />
              <img alt="" src={cf2022} /> <br />
              <br />
              <Text>
                The OCMA is pleased to announce details and open registration
                for the 2022 Conference held May 25 - 27 at
                <a target="_blank" href="https://fernresort.com/">
                  {' '}
                  Fern Resort
                </a>
                , Orillia.
                <br />
                <br />
                Our keynote speaker is author and educator{' '}
                <strong>Sunil Singh</strong> who will show us how math history
                and personal journeys build stories that can broaden the purpose
                of learning mathematics.
                <br />
                <br />
                Our members, some who we haven't seen in a long time, will be
                sharing new approaches to old ideas and discussing what has or
                hasn't changed in the classroom while leading us in face-to-face
                sessions about traditional ways of teaching math and developing
                numeracy that are still relevant today
                <br />
                <br />
                We'll be celebrating our 40th anniversary - 2 years late - but
                also celebrating the return of in-person events. There will be
                plenty of opportunities for college math educators across the
                province to gather and put the important events and issues from
                the past 2 years in context of their past and future stories.
                <br />
                <br />
                We can't wait to welcome you <strong>Back to the Past!</strong>
              </Text>
              <StyledLink
                h4
                border
                to="https://drive.google.com/drive/folders/1i72BFRCdJy6aU9XcE4Y1KH-KJEwl7Ub0"
              >
                Download Session Descriptions and Schedule
              </StyledLink>
            </div> */}
            {/* <br />
            <br />
            <hr /> */}
            {/* <Text h1>$500*</Text>
            <Text>
              *Early bird rate in effect until April 29th. Includes
              registration, meals, activities, and 2 nights single
              accommodation. Rate increases to $600 after April 29th.
            </Text>{' '}
            <div
              style={{
                backgroundColor: 'lightgrey',
                fontSize: '15px',
                padding: '5px',
                margin: '5px 0 10px',
                display: 'inline-block',
              }}
            >
              <small>
                Final registration and payment is handled directly by Fern
                Resort. Instructions, full rate details, and conditions are on
                the registration form.
              </small>
            </div> */}
            {/* <StyledLink
              h4
              border
              to="https://docs.google.com/forms/d/e/1FAIpQLSfNrZt_ciEKVwUcBAPsYEr5rt6R4Fu0eHQTyyfDKjJNHj2aFA/viewform"
            >
              Pre-registration Form
            </StyledLink> */}
            {/* <br />
            <br />
            <br />
         <Text
              h2
              css={css`
                margin-bottom: 10px;
              `}
            >
              Upcoming Events
            </Text>
            <div className="text-center">
              <h3>5 months, 5𝜋/6, -.87+.50j</h3>
              <img alt="" src={Conference} />
              <p>
                ... whatever representation you chose, we need to rotate the
                date of the 2021 OCMA Conference to October 25 - 27, 2021. Why?
                Quite simply we miss being with you and we think that the Fall
                will be our best chance to have an event with some sort of
                in-person component. But don't worry, all participants will have
                the ability to join remotely if in-person doesn't work for them.
                This is a one-time change; we expect to be back to our annual
                May conference in 2022.
              </p>
            </div>
            <br />
            <br /> */}
            <Text
              h2
              css={css`
                margin-bottom: 10px;
                text-align: center;
              `}
            >
              Past Events
            </Text>
            <br />
            <div className="row">
              <div className="col-lg-6 text-center">
                <Text
                  h3
                  css={css`
                    margin-bottom: 0;
                    color: black;
                  `}
                >
                  Conferences
                </Text>
                <Text
                  p
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  OCMA Conferences are gatherings where the Ontario Mathematics
                  community connects to discuss and share the latest and
                  greatest developments in Canadian Mathematics.
                </Text>
              </div>
              <div className="col-lg-6 text-center">
                <Text
                  h3
                  css={css`
                    margin-bottom: 0;
                    color: black;
                  `}
                >
                  Workshops
                </Text>
                <Text
                  p
                  css={css`
                    margin-bottom: 0;
                  `}
                >
                  OCMA Workshops are opportunities to learn and experience
                  concepts in hands-on session led by leaders in the Ontario
                  Mathematics community.
                </Text>
              </div>
            </div>
          </ContentSection>
          <ContentSection>
            {eventsACF.past_events.map((year, i) => (
              <React.Fragment key={i}>
                {year.year && (
                  <div
                    className="mt-5 mb-2"
                    css={css`
                      font-size: 32px;
                    `}
                  >
                    {year.year}
                  </div>
                )}
                <div className="row">
                  {year.events.map((event, j) => (
                    <div
                      className="col-lg-6 pt-3 pb-5"
                      key={`recent_event_${i}${j}`}
                    >
                      {event.title && (
                        <div
                          css={css`
                            background-color: ${styles.colors.whiteAlt};
                            border-radius: ${styles.borderRadius.small};
                            box-shadow: ${styles.boxShadow.small};
                            padding: 15px;
                          `}
                        >
                          <div
                            className="row"
                            css={css`
                              height: 100%;
                            `}
                          >
                            <div className="col-7">
                              {event.image ? (
                                <GatsbyImage
                                  fluid={
                                    event.image.localFile.childImageSharp.fluid
                                  }
                                />
                              ) : (
                                <div
                                  css={[
                                    css`
                                      ${styles.flexCenter()};
                                      border: 2px solid
                                        ${styles.colors.blueDark};
                                      height: 100%;
                                      min-height: 165px;
                                      background-color: ${styles.colors.white};
                                    `,
                                  ]}
                                >
                                  <div
                                    css={css`
                                      display: inline-block;
                                      position: relative;
                                    `}
                                  >
                                    <Text
                                      css={css`
                                        text-transform: uppercase;
                                        color: ${styles.colors.red};
                                        position: relative;
                                        display: inline-block;
                                        font-weight: bold;
                                        padding-bottom: 4px;
                                        margin-bottom: 0;
                                      `}
                                    >
                                      {event.subtitle}
                                    </Text>
                                    <OcmaColorsBar
                                      css={css`
                                        position: absolute;
                                        height: 5px;
                                        width: 100%;
                                      `}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className="col-5 pl-0"
                              css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                              `}
                            >
                              <Text
                                pSmall
                                className="eventsLinkText"
                                css={css`
                                  font-weight: bold;
                                `}
                              >
                                {event.title}
                              </Text>
                              {event.image && (
                                <Text
                                  pSmall
                                  css={css`
                                    color: ${styles.colors.blueLight};
                                  `}
                                >
                                  {event.subtitle}
                                </Text>
                              )}
                              <StyledLink
                                to={`/${event.link}`}
                                css={css`
                                  margin-bottom: 0;
                                `}
                              >
                                View Event
                              </StyledLink>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </ContentSection>
          <ContentSection>
            <Text
              h2
              css={css`
                color: ${styles.colors.red};
              `}
            >
              {eventsACF.archive_events_title}
            </Text>
            <div className="row">
              {eventsACF.archive_events.map((event, i) => (
                <div
                  className="col-md-3"
                  key={`archive_event_${i}`}
                  css={css`
                    margin-bottom: ${styles.margin.mid};
                  `}
                >
                  {event.file[0].acf_fc_layout && (
                    <Link
                      to={
                        event.file[0].acf_fc_layout === 'link'
                          ? event.file[0].link
                          : event.file[0].file.url.source_url
                      }
                    >
                      <Text
                        pSmall
                        css={css`
                          font-weight: bold;
                          margin-bottom: 0;
                        `}
                      >
                        {event.title}
                      </Text>
                      <Text
                        pSmall
                        css={css`
                          color: ${styles.colors.blueLight};
                        `}
                      >
                        {event.year}
                      </Text>
                      <Text
                        css={css`
                          color: ${styles.colors.blueDark};
                        `}
                      >
                        Download PDF
                      </Text>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </ContentSection>
        </CustomContainer>
      </MainContentWrap>
    </Layout>
  )
}
